/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getProjectsDB } from "../lib/db";
// import ProjectsFilter from "./projects-filter";
import "../styles/projects.css";
import LoadingSpinner from "./loading-spinner";
import Footer from "./footer";
import DiscoverMoreButton from "./discover-more-button";
import ProjectInfoDiv from "./project-info-div";
import { scrollToTop } from "../lib/utils";
import Navbar from "./navbar";
import BtnMenu from "./btn-menu";

const Projects = ({
  home,
  projectsProp,
  windowWidth,
  bgColor,
  lightColor,
  darkColor,
  primaryColor,
  desktopSize,
  mobileSize,
}) => {
  const ref = useRef(null);
  const navigate = useNavigate();
  const [projects, setProjects] = useState();
  const [showProjectDescription, setShowProjectDescription] = useState();
  const [isLoading, setIsLoading] = useState();
  const [widthProjectDiv, setWidthProjectDiv] = useState();
  // const [filterProjects, setFilterProjects] = useState();

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    getProjects();
    // }, [filterProjects, home]);
  }, [home]);

  useEffect(() => {
    setWidthProjectDiv(ref.current ? ref.current.offsetWidth : 0);
    // }, [filterProjects, home]);
  }, [windowWidth]);

  const getProjects = async () => {
    setIsLoading(true);
    if (home) {
      setProjects(await getProjectsDB("home"));
    } else {
      // setProjects(await getProjectsDB("projects", filterProjects));
      setProjects(await getProjectsDB("projects"));
    }
    setIsLoading();
  };

  const goToProjectDetails = (projectId) => {
    navigate(`/project/${projectId}`);
  };

  const goToProjects = () => {
    navigate(`/projects/`);
  };
  const onMouseOverProjectDiv = (key) => {
    setShowProjectDescription(key + 1);
    document.getElementById(`project-item-home-img-${key}`).style.transform =
      "scale(1.3) rotate(-5deg)";
    document.getElementById(`project-item-home-img-${key}`).style.zIndex = 1;
    document.getElementById(`project-item-home-img-${key}`).style.opacity = 0.5;
  };

  const onMouseOutProjectDiv = (key) => {
    setShowProjectDescription();
    document.getElementById(`project-item-home-img-${key}`).style.transform =
      "scale(1)";
    document.getElementById(`project-item-home-img-${key}`).style.zIndex = 0;
    document.getElementById(`project-item-home-img-${key}`).style.opacity = 1;
  };

  let bigDiv = false;

  return (
    <div style={{ background: bgColor }} className={`z-10 w-full`}>
      {windowWidth > mobileSize && !home && (
        <Navbar
          windowWidth={windowWidth}
          lightColor={lightColor}
          darkColor={darkColor}
          desktopSize={desktopSize}
          mobileSize={mobileSize}
          bgColor={bgColor}
          primaryColor={primaryColor}
          isActive={"Projects"}
        />
      )}
      {windowWidth <= mobileSize && !home && (
        <BtnMenu
          windowWidth={windowWidth}
          mobileSize={mobileSize}
          primaryColor={primaryColor}
        />
      )}
      {isLoading && projectsProp && <LoadingSpinner />}
      {!isLoading && (
        <div
          className="flex flex-col items-center w-full"
          style={{
            paddingLeft: !projectsProp && windowWidth > 1200 && "100px",
            paddingRight: !projectsProp && windowWidth > 1200 && "100px",
            marginTop: windowWidth > mobileSize && "80px",
          }}
        >
          {home && (
            <div className="flex flex-col justify-center items-center w-[90%] pb-16">
              <p
                className={`pt-20 text-base font-bold`}
                style={{ color: primaryColor }}
              >
                WORK
              </p>
              <p
                style={{
                  color: lightColor,
                  textAlign: windowWidth < mobileSize && "left",
                }}
                className={`text text-[18px] mt-5 text-center w-full`}
              >
                A small sample of some of our team’s work. ¿Need any more
                information?
              </p>
              <p
                style={{
                  color: lightColor,
                  textAlign: windowWidth < mobileSize && "left",
                }}
                className={`text text-[18px] text-center w-full`}
              >
                Do not hesitate to
                <a href="mailto:info@dynatech2012.com" className="">
                  <span style={{ color: primaryColor }}> contact us.</span>
                </a>
              </p>
            </div>
          )}
          {/* {!home && (
            <div className="flex justify-center items-center w-full">
              <ProjectsFilter setFilterProjects={setFilterProjects} />
            </div>
          )} */}
          <div
            ref={ref}
            className="w-full flex flex-wrap items-center justify-center"
          >
            {home &&
              projects &&
              projects.map((project, key) => {
                return (
                  <div
                    key={key}
                    className={`flex relative cursor-pointer project-item-home-div m-[0.2%]`}
                    style={{
                      // minHeight:
                      //   windowWidth <= mobileSize
                      //     ? "350px"
                      //     : windowWidth <= 1200 && "550px",
                      height:
                        windowWidth <= 1200
                          ? widthProjectDiv * 0.6 + "px"
                          : widthProjectDiv * 0.35 + "px",
                      width:
                        key % 3 === 0
                          ? "59.6%"
                          : key % 3 !== 0
                          ? "39.6%"
                          : "100%",
                    }}
                    onClick={() => {
                      goToProjectDetails(project.projectID);
                    }}
                    onMouseOver={() => {
                      onMouseOverProjectDiv(key);
                    }}
                    onMouseOut={() => {
                      onMouseOutProjectDiv(key);
                    }}
                  >
                    <ProjectInfoDiv
                      isHome={true}
                      windowWidth={windowWidth}
                      projectsProp={projectsProp}
                      project={project}
                      keyprop={key}
                      showProjectDescription={showProjectDescription}
                      lightColor={lightColor}
                      darkColor={darkColor}
                    />
                  </div>
                );
              })}

            {projectsProp &&
              projects &&
              projects.map((project, key) => {
                const row = Math.trunc(key / 2) % 2;
                const element = Math.round(key / 2) % 2;

                if (
                  (row === 0 && element === 0) ||
                  (row === 1 && element === 0)
                ) {
                  bigDiv = true;
                }
                if (
                  (row === 0 && element === 1) ||
                  (row === 1 && element === 1)
                ) {
                  bigDiv = false;
                }

                return (
                  <div
                    key={key}
                    className={`flex relative cursor-pointer project-item-home-div mb-1`}
                    style={{
                      // minHeight:
                      //   windowWidth <= mobileSize
                      //     ? "350px"
                      //     : windowWidth <= 1200 && "550px",
                      height:
                        windowWidth <= 1200
                          ? widthProjectDiv * 0.6 + "px"
                          : widthProjectDiv * 0.35 + "px",
                      width:
                        bigDiv && projects.length > 1
                          ? "60%"
                          : !bigDiv && projects.length > 1
                          ? "40%"
                          : "100%",
                    }}
                    onClick={() => {
                      goToProjectDetails(project.projectID);
                    }}
                    onMouseOver={() => {
                      onMouseOverProjectDiv(key);
                    }}
                    onMouseOut={() => {
                      onMouseOutProjectDiv(key);
                    }}
                  >
                    <ProjectInfoDiv
                      windowWidth={windowWidth}
                      bigDiv={bigDiv}
                      projectsProp={projectsProp}
                      project={project}
                      keyprop={key}
                      showProjectDescription={showProjectDescription}
                      lightColor={lightColor}
                      darkColor={darkColor}
                    />
                  </div>
                );
              })}
          </div>

          {home && <DiscoverMoreButton action={goToProjects} />}
        </div>
      )}
      {!home && (
        <Footer
          windowWidth={windowWidth}
          primaryColor={primaryColor}
          desktopSize={desktopSize}
          lightColor={lightColor}
          mobileSize={mobileSize}
          isActive={"Projects"}
        />
      )}
    </div>
  );
};

export default Projects;
