import { useState } from "react";

const BlockType2 = ({
  block,
  windowWidth,
  sidePadding,
  darkColor,
  lightColor,
  desktopSize,
  primaryColor,
}) => {
  const [isDark] = useState(block.dark);
  return (
    <div
      className="w-full flex items-center justify-between"
      style={{
        paddingLeft:
          windowWidth < desktopSize
            ? sidePadding?.mobile + "px"
            : sidePadding?.desktop + "px",
        paddingRight:
          windowWidth < desktopSize
            ? sidePadding?.mobile + "px"
            : sidePadding?.desktop + "px",
        paddingTop:
          windowWidth < desktopSize
            ? block?.padding?.mobile?.top + "px"
            : block?.padding?.desktop?.top + "px",
        paddingBottom:
          windowWidth < desktopSize
            ? block?.padding?.mobile?.bottom + "px"
            : block?.padding?.desktop?.bottom + "px",

        flexDirection:
          windowWidth < desktopSize
            ? "column"
            : block.inverted
            ? "row-reverse"
            : "row",
        // justifyContent: windowWidth < desktopSize && "start",
        background: isDark ? darkColor : lightColor,
        color: isDark ? lightColor : darkColor,
      }}
    >
      <div
        style={{
          width:
            windowWidth < desktopSize ? "100%" : block.inverted ? "58%" : "42%",
          paddingLeft: block.inverted && "24px",
          paddingRight: !block.inverted && "24px",
          // padding: windowWidth < desktopSize && "0 10px",
          textAlign:
            windowWidth > desktopSize && !block.inverted
              ? "left"
              : windowWidth > desktopSize && block.inverted
              ? "right"
              : windowWidth < desktopSize && "center",
        }}
      >
        <div
          className="flex flex-wrap "
          style={{
            width: windowWidth < desktopSize && "100%",
            justifyContent:
              windowWidth > desktopSize && !block.inverted
                ? "flex-start"
                : windowWidth > desktopSize && block.inverted
                ? "flex-end"
                : windowWidth < desktopSize && "center",
            paddingBottom: "5px",
          }}
        >
          {block.categories.map((category, key) => (
            <div
              key={key}
              style={{
                background: primaryColor,
              }}
              className="flex justify-center items-center text-center text-[12px] font-bold text-[#034857] mr-1 mt-1 px-4 py-1.5"
            >
              <p>{category.toUpperCase()}</p>
            </div>
          ))}
        </div>
        <p className="font-bold text-[40px]">{block?.title}</p>
      </div>
      <div
        style={{
          width:
            windowWidth < desktopSize ? "100%" : block.inverted ? "42%" : "58%",
          padding: windowWidth < desktopSize && "10px",
          textAlign: windowWidth < desktopSize && "center",
        }}
      >
        <p
          className="font-normal text-[18px] text-justify"
          dangerouslySetInnerHTML={{ __html: block?.content }}
        ></p>
      </div>
    </div>
  );
};

export default BlockType2;
