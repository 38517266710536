import Carousel from "better-react-carousel";
import { useEffect, useState } from "react";
import { getCarrouselImagesDB } from "../lib/db";
const CarrouselHome = ({ windowWidth, desktopSize, mobileSize }) => {
  const [carrouselImages, setCarrouselImages] = useState();
  const [cols, setCols] = useState();
  useEffect(() => {
    getCarrouselImages();
  }, []);

  useEffect(() => {
    if (carrouselImages) {
      setCols(parseInt(carrouselImages.length / 2));
    }
  }, [carrouselImages]);

  const getCarrouselImages = async () => {
    setCarrouselImages(await getCarrouselImagesDB());
  };

  return (
    <div className="w-full flex justify-center items-center py-10 px-10">
      <Carousel
        cols={windowWidth > desktopSize ? cols : 2}
        rows={1}
        gap={0}
        loop={true}
        hideArrow={true}
        autoplay={windowWidth > mobileSize ? 6000 : 2000}
        // mobileBreakpoint={768}
      >
        {carrouselImages?.map((image, key) => {
          return (
            <Carousel.Item key={key}>
              <img src={image} alt="Dynatech" className="" />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
};
export default CarrouselHome;
