import { useEffect } from "react";

const CompanyInfo1 = ({
  companyInfoPosition,
  positionToShowDiv,
  windowWidth,
}) => {
  useEffect(() => {
    if (
      companyInfoPosition + positionToShowDiv < window.innerHeight &&
      companyInfoPosition > 0
    ) {
      document.getElementById("company-info-1-div").style.animation =
        "opacityIn 2.5s";
      document.getElementById("company-info-1-div").style.opacity = "1";
    } else if (window.scrollY === 0) {
      document.getElementById("company-info-1-div").style.animation =
        "opacityOut 0.5s";
      document.getElementById("company-info-1-div").style.opacity = "0";
    }
  }, [companyInfoPosition, positionToShowDiv]);
  return (
    <div
      style={{ marginRight: windowWidth >= 1250 && "90px" }}
      className="w-[343px] mb-[90px]"
    >
      <p className="text-[34px] font-bold">From vision to version 1.0</p>
      <p className="text-[16px] mt-6">
        Are you a startup founder or working on a new software idea where a
        mobile app or a custom software solution is critical to your success?
      </p>
      <p className="text-[16px] mt-6">
        We think you’re not looking for a traditional software company, you are
        looking for a partner, and we can help you. We focus on the complete
        cycle: consult, design, develop, and launch.
      </p>
    </div>
  );
};
export default CompanyInfo1;
