import BlockAsset from "./block-asset";

const BlockType3 = ({ block, windowWidth, mobileSize }) => {
  return (
    ((windowWidth > mobileSize && !block.hideForDesktop) ||
      (windowWidth <= mobileSize && block.assetMobile)) && (
      <div className="w-full flex flex-col items-center justify-center">
        <BlockAsset
          asset={
            windowWidth > mobileSize
              ? block?.asset
              : block?.assetMobile
              ? block?.assetMobile
              : block?.asset
          }
          title={block.title}
          className={"w-full"}
        />
      </div>
    )
  );
};

export default BlockType3;
