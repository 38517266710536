const Portatil2Footer = () => {
  return (
    <div className="absolute left-0 bottom-0">
      <img
        className="w-[80%]"
        src="/assets/background/portatil-2.svg"
        alt="Dynatech"
      />
    </div>
  );
};
export default Portatil2Footer;
