const SemiCircleTopServices = () => {
  return (
    <div className="bg-[#6787DA] absolute top-[-1px] h-[120px] w-[100%] border-0">
      <img
        className="max-h-[120px] w-[100%]"
        src="/assets/background/semi-circle-services.png"
        alt=""
      />
    </div>
  );
};
export default SemiCircleTopServices;
