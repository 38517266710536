import { useState } from "react";

const BlockType5 = ({
  block,
  windowWidth,
  sidePadding,
  darkColor,
  lightColor,
  desktopSize,
  mobileSize,
}) => {
  const [isDark] = useState(block.dark);
  const [blockContent] = useState(block?.content);
  return (
    <div
      className="w-full flex flex-wrap items-center justify-around "
      style={{
        background: isDark ? darkColor : lightColor,
        color: isDark ? lightColor : darkColor,
        paddingLeft:
          windowWidth < desktopSize
            ? sidePadding?.mobile + "px"
            : sidePadding?.desktop + "px",
        paddingRight:
          windowWidth < desktopSize
            ? sidePadding?.mobile + "px"
            : sidePadding?.desktop + "px",
        paddingTop:
          windowWidth < desktopSize
            ? block?.padding?.mobile?.top + "px"
            : block?.padding?.desktop?.top + "px",
        paddingBottom:
          windowWidth < desktopSize
            ? block?.padding?.mobile?.bottom + "px"
            : block?.padding?.desktop?.bottom + "px",
        flexDirection: windowWidth < mobileSize && "column",
        justifyContent: windowWidth < mobileSize && "center",
      }}
    >
      {blockContent?.map((content, key) => {
        return (
          <div
            className="flex flex-col justify-center align-center my-6"
            key={key}
            style={{
              textAlign: windowWidth <= desktopSize && "center",
              width: windowWidth < desktopSize && "100%",
            }}
          >
            <div>
              <p
                style={{
                  marginTop: windowWidth < desktopSize && key > 0 && "30px",
                }}
                className="text-[18px]"
              >
                {content?.title}
              </p>
              {content?.content?.map((item, key) => {
                return (
                  <div
                    key={key}
                    className=""
                    style={
                      {
                        // paddingRight: windowWidth > desktopSize ? "40%" : "0",
                      }
                    }
                  >
                    <p
                      className="text-[26px] font-semibold mt-[16px] leading-none"
                      style={{ textIndent: "-10px", paddingLeft: "10px" }}
                    >
                      {item}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BlockType5;
