import { useState } from "react";
import BlockAsset from "./block-asset";

const BlockType4 = ({
  block,
  windowWidth,
  sidePadding,
  darkColor,
  lightColor,
  desktopSize,
}) => {
  const [isDark] = useState(block.dark);

  return (
    <div
      style={{
        background: isDark ? darkColor : lightColor,
        paddingLeft:
          windowWidth < desktopSize
            ? sidePadding?.mobile + "px"
            : sidePadding?.desktop + "px",
        paddingRight:
          windowWidth < desktopSize
            ? sidePadding?.mobile + "px"
            : sidePadding?.desktop + "px",
        paddingTop:
          windowWidth < desktopSize
            ? block?.padding?.mobile?.top + "px"
            : block?.padding?.desktop?.top + "px",
        paddingBottom:
          windowWidth < desktopSize
            ? block?.padding?.mobile?.bottom + "px"
            : block?.padding?.desktop?.bottom + "px",
      }}
      className="w-full flex flex-col items-center justify-between"
    >
      <div className="flex w-full">
        <div className="w-1/2 mr-2.5">
          <BlockAsset
            asset={block.assetLeft}
            title={block.title}
            className={"w-full h-[470px] "}
          />
        </div>
        <div className="w-1/2 ml-2.5">
          <BlockAsset
            asset={block.assetRight}
            title={block.title}
            className={"w-full h-[470px] "}
          />
        </div>
      </div>
    </div>
  );
};

export default BlockType4;
