import Portatil1Services from "./background/portatil-1-services";
import SemiCircleTopServices from "./background/semi-circle-top-services";
// import DiscoverMoreButton from "./discover-more-button";

const ServicesHome = ({
  windowWidth,
  lightColor,
  primaryColor,
  desktopSize,
  mobileSize,
  bgColor,
}) => {
  return (
    <div
      style={{ color: lightColor, paddingBottom: "80px" }}
      className={`bg-[#6787DA] w-full flex flex-col justify-center items-center relative`}
    >
      {windowWidth > desktopSize && <Portatil1Services />}
      <SemiCircleTopServices bgColor={bgColor} windowWidth={windowWidth} />
      <div
        className="flex flex-col items-center justify-center"
        style={{
          width: windowWidth < mobileSize ? "90%" : "42%",
          paddingTop: "200px",
        }}
      >
        <p style={{ color: primaryColor }} className={`text-[16px] font-bold`}>
          SERVICES
        </p>
        <p
          style={{ color: lightColor }}
          className={`text text-[18px] mt-5 text-left`}
        >
          {/* We want to be your digital partner. We work with clients in many
          different fields that rely on technology, and many of them are working
          on new software and hardware-driven initiatives. That’s where we come
          in, helping them translate their vision into a polished, market-ready
          product. */}
          We are your dedicated digital partner, collaborating with clients across 
          diverse industries, empowering their technology-driven ventures. Whether 
          it's pioneering software or innovative hardware projects, we step in to 
          transform their vision into a refined, market-ready reality.
        </p>
        <p
          style={{ color: lightColor }}
          className={`text- text-[18px] mt-5 text-left`}
        >
          {/* Our driving focus is setting clear expectations and delivering
          high-quality products to our customers on time and on budget. With
          that, we hope to convince you to become our long-term partner and
          continue to work with you as a part of your business. */}
          At our core, we prioritize transparency, ensuring clear expectations 
          and delivering top-notch products on-time and within budget. Our goal 
          is to establish a lasting partnership with you, seamlessly integrating 
          our services into your business journey. Let's embark on this transformative 
          tech journey together.
        </p>
      </div>
      <div className="flex flex-wrap justify-center w-[100%] mt-14">
        <div className="flex flex-wrap items-center justify-center">
          <div className="flex flex-col w-[300px] justify-center p-6">
            <p className="font-bold text-[20px]">Native App Development</p>
            <p className="text-[16px] mt-4">
            Unlock the potential of your ideas with meticulously engineered iOS and Android apps. See your vision on your smartphone with our tailored solutions 📱💡🚀
            </p>
          </div>
          <div className="flex flex-col w-[300px] justify-center p-6">
            <p className="font-bold text-[20px] ">Web</p>
            <p className="font-bold text-[20px] ">development</p>
            <p className="text-[16px]  mt-4">
            Our process delivers customized websites beyond templates, ensuring a distinctive online presence aligned with your unique business and branding. 🖥️🌐
            </p>
          </div>
        </div>
        <div className="flex flex-wrap items-center justify-center">
          <div className="flex flex-col w-[300px] justify-center p-6">
            <p className="font-bold text-[20px] ">UX / UI</p>
            <p className="font-bold text-[20px] ">Design</p>
            <p className="text-[16px]  mt-4">
            Step into a world of user-centric UX/UI designs, prioritizing usability and aesthetic finesse. Our seamless designs amplify your online presence. 🌈✨</p>
            </div>
          <div className="flex flex-col items-end justify-center w-[300px] p-6">
            <p className="font-bold text-left text-[20px] ">
              Integrated cloud back-end solutions
            </p>
            <p className="text-[16px]  text-left mt-4">
            Beyond websites, we specialize in providing robust cloud back-end solutions, integrating seamlessly with your apps, services and tech components. 🌐🚀</p>
          </div>
        </div>
      </div>
      {/* <DiscoverMoreButton /> */}
    </div>
  );
};
export default ServicesHome;
