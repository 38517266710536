import { useEffect, useState } from "react";
import { getMobilePicturesDB } from "../../lib/db";
import "../../styles/mobile-start.css";
import Circle5Start from "./circle5-start";

const MobileStart = ({ windowWidth, windowHeight }) => {
  const [mobilePictures, setMobilePictures] = useState();
  const [pictureSelected, setPictureSelected] = useState();
  const [timeToChangePicture] = useState(15);

  let key = 0;
  useEffect(() => {
    getMobilePictures();
  }, []);

  useEffect(() => {
    if (mobilePictures?.length > 0) {
      setPictureSelected(mobilePictures[key]);
      setTimeout(() => updatePictureMobile(), timeToChangePicture * 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mobilePictures]);

  const updatePictureMobile = () => {
    if (key <= mobilePictures?.length) {
      key = key + 1;
      if (key < mobilePictures?.length) {
        setPictureSelected(mobilePictures[key]);
      } else {
        setPictureSelected(mobilePictures[0]);
      }
      setTimeout(() => updatePictureMobile(), timeToChangePicture * 1000);
      if (key === mobilePictures?.length) {
        key = 0;
      }
    }
  };

  const getMobilePictures = async () => {
    setMobilePictures(await getMobilePicturesDB());
  };

  return (
    <div>
      <Circle5Start windowHeight={windowHeight} windowWidth={windowWidth} />
      <div
        className="absolute w-[50%]"
        style={{
          left: windowWidth / 1.8 + "px",
          bottom: -(windowWidth / 80) + "%",
        }}
      >
        <img
          src="/assets/background/mobile.svg"
          alt="Dynatech"
          className="w-full h-full"
        />
      </div>
      {mobilePictures && pictureSelected && mobilePictures.length > 0 && (
        <div
          className="absolute w-[50%]"
          style={{
            left: windowWidth / 1.8 + "px",
            bottom: -(windowWidth / 80) + "%",
          }}
        >
          {mobilePictures.map((picture, key) => {
            return (
              picture === pictureSelected && (
                <img
                  key={key}
                  className="animation-in w-full h-full"
                  src={pictureSelected}
                  alt=""
                />
              )
            );
          })}
        </div>
      )}
    </div>
  );
};
export default MobileStart;
