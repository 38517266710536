import BlockAsset from "../block-asset";
import CategoriesType6 from "./categories-type-6";

const DesignRightType6 = ({
  block,
  content,
  windowWidth,
  sidePadding,
  darkColor,
  lightColor,
  desktopSize,
  mobileSize,
  primaryColor,
}) => {
  return (
    <div
      className="w-full flex items-center justify-between"
      style={{
        paddingLeft:
          windowWidth < desktopSize
            ? sidePadding?.mobile + "px"
            : sidePadding?.desktop + "px",
        paddingRight:
          windowWidth < desktopSize
            ? sidePadding?.mobile + "px"
            : sidePadding?.desktop + "px",
        paddingTop:
          windowWidth < desktopSize
            ? block?.padding?.mobile?.top + "px"
            : block?.padding?.desktop?.top + "px",
        paddingBottom:
          windowWidth < desktopSize
            ? block?.padding?.mobile?.bottom + "px"
            : block?.padding?.desktop?.bottom + "px",
        flexDirection: windowWidth < mobileSize && "column",
        background: content?.dark ? darkColor : lightColor,
        color: content?.dark ? lightColor : darkColor,
      }}
    >
      <div
        className="w-1/2"
        style={{
          width: windowWidth < mobileSize && "100%",
          marginBottom: "5px",
          paddingRight: windowWidth < mobileSize ? "0px" : "40px",
          maxHeight: windowWidth < mobileSize && "100%",
        }}
      >
        <CategoriesType6
          categories={content?.categories}
          primaryColor={primaryColor}
        />
        <p className="text-[40px] font-bold">{content?.title}</p>
        <p
          className="text-[18px]"
          dangerouslySetInnerHTML={{ __html: content?.text }}
        ></p>
      </div>
      <div
        className="w-1/2"
        style={{
          width: windowWidth < mobileSize && "100%",
          paddingLeft: windowWidth < mobileSize ? "0px" : "40px",
        }}
      >
        <BlockAsset
          asset={content.asset}
          title={content.text}
          className={"w-full h-full"}
        />
      </div>
    </div>
  );
};
export default DesignRightType6;
