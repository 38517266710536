const DiscoverMoreButton = ({ action }) => {
  return (
    <div className="flex flex-col justify-center items-center w-full pt-20 z-10">
      <button
        onClick={action && action}
        className="text-[#FEECEF] text-sm font-bold h-[42px] w-[300px] border"
      >
        DISCOVER MORE
      </button>
    </div>
  );
};

export default DiscoverMoreButton;
