import CarrouselHome from "./carrousel-home";
import Experience from "./experience";
import Footer from "./footer";
import BtnMenu from "./btn-menu";
import Projects from "./projects";
import Start from "./start";
import ServicesHome from "./services-home";
import { useEffect } from "react";
import { scrollToTop } from "../lib/utils";

const Home = ({
  windowWidth,
  bgColor,
  darkColor,
  lightColor,
  primaryColor,
  desktopSize,
  mobileSize,
  windowHeight,
}) => {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div className="flex flex-col justify-center items-center">
      <BtnMenu
        windowWidth={windowWidth}
        mobileSize={mobileSize}
        primaryColor={primaryColor}
      />
      <Start
        windowWidth={windowWidth}
        darkColor={darkColor}
        lightColor={lightColor}
        desktopSize={desktopSize}
        mobileSize={mobileSize}
        bgColor={bgColor}
        primaryColor={primaryColor}
        windowHeight={windowHeight}
      />
      <ServicesHome
        windowWidth={windowWidth}
        lightColor={lightColor}
        desktopSize={desktopSize}
        mobileSize={mobileSize}
        primaryColor={primaryColor}
        bgColor={bgColor}
      />
      <Projects
        home={true}
        windowWidth={windowWidth}
        lightColor={lightColor}
        darkColor={darkColor}
        desktopSize={desktopSize}
        mobileSize={mobileSize}
        bgColor={bgColor}
        primaryColor={primaryColor}
      />
      <CarrouselHome
        windowWidth={windowWidth}
        lightColor={lightColor}
        darkColor={darkColor}
        desktopSize={desktopSize}
        mobileSize={mobileSize}
        bgColor={bgColor}
        primaryColor={primaryColor}
      />
      <Experience
        windowWidth={windowWidth}
        bgColor={bgColor}
        lightColor={lightColor}
        desktopSize={desktopSize}
        mobileSize={mobileSize}
      />
      <Footer
        isHome={true}
        windowWidth={windowWidth}
        primaryColor={primaryColor}
        desktopSize={desktopSize}
        lightColor={lightColor}
        mobileSize={mobileSize}
      />
    </div>
  );
};
export default Home;
