const Circle5Start = ({ windowWidth, windowHeight }) => {
  return (
    <div
      className="absolute"
      style={{
        left: windowWidth/1.3 + "px",
        top: windowHeight/1.2  + "px",
      }}
    >
      <img src="/assets/background/circle-5-start.svg" alt="Dynatech" />
    </div>
  );
};
export default Circle5Start;
