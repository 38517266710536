import { useEffect, useState } from "react";
import Design3AssetsType7 from "./type-7/design-3-assets-type-7";
import Design4AssetsOptionAType7 from "./type-7/design-4-assets-option-a-type-7";
import Design4AssetsOptionBType7 from "./type-7/design-4-assets-option-b-type-7";

const BlockType7 = ({
  block,
  windowWidth,
  sidePadding,
  darkColor,
  lightColor,
  desktopSize,
  mobileSize,
}) => {
  const [isDark] = useState(block.dark);
  const [assets, setAssets] = useState();
  useEffect(() => {
    if (block.assets.length === 4) {
      setAssets({
        assetLeftUp: { src: block.assets[0].src, type: block.assets[0].type },
        assetLeftDown: { src: block.assets[1].src, type: block.assets[1].type },
        assetRightUp: { src: block.assets[2].src, type: block.assets[2].type },
        assetRightDown: {
          src: block.assets[3].src,
          type: block.assets[3].type,
        },
      });
    }
    if (block.assets.length === 3) {
      setAssets({
        assetLeftUp: { src: block.assets[0].src, type: block.assets[0].type },
        assetRightUp: { src: block.assets[2].src, type: block.assets[2].type },
        assetDown: {
          src: block.assets[2].src,
          type: block.assets[2].type,
        },
      });
    }
  }, [block]);
  return (
    <div
      style={{
        background: isDark ? darkColor : lightColor,
        paddingLeft:
          windowWidth < desktopSize
            ? sidePadding?.mobile + "px"
            : sidePadding?.desktop + "px",
        paddingRight:
          windowWidth < desktopSize
            ? sidePadding?.mobile + "px"
            : sidePadding?.desktop + "px",
        paddingTop:
          windowWidth < desktopSize
            ? block?.padding?.mobile?.top + "px"
            : block?.padding?.desktop?.top + "px",
        paddingBottom:
          windowWidth < desktopSize
            ? block?.padding?.mobile?.bottom + "px"
            : block?.padding?.desktop?.bottom + "px",
      }}
      className="w-full flex flex-col items-center justify-between"
    >
      {assets && block.assets.length === 4 && block.designOption === "A" && (
        <Design4AssetsOptionAType7
          assets={assets}
          block={block}
          windowWidth={windowWidth}
          mobileSize={mobileSize}
        />
      )}
      {assets && block.assets.length === 4 && block.designOption === "B" && (
        <Design4AssetsOptionBType7
          assets={assets}
          block={block}
          windowWidth={windowWidth}
          mobileSize={mobileSize}
        />
      )}
      {assets && block.assets.length === 3 && (
        <Design3AssetsType7 assets={assets} block={block} />
      )}
    </div>
  );
};

export default BlockType7;
