import { useNavigate } from "react-router-dom";
import "../styles/btn-menu.css";
import { useEffect, useState } from "react";

const BtnMenu = ({ windowWidth, primaryColor, mobileSize }) => {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState();
  const [linkedinLogo, setLinkedinLogo] = useState(
    "/assets/linkedin-navbar.svg"
  );
  const [homeLinkPosition, setHomeLinkPosition] = useState();

  useEffect(() => {
    if (isExpanded) {
      setTimeout(() => {
        setHomeLinkPosition(
          Math.round(
            document
              ?.getElementById("home-link-navbar")
              ?.getBoundingClientRect()?.top
          )
        );
      }, [300]);
    }
  }, [isExpanded]);

  window.addEventListener(
    "resize",
    function () {
      setHomeLinkPosition(
        Math.round(
          document?.getElementById("home-link-navbar")?.getBoundingClientRect()
            ?.top
        )
      );
    },
    true
  );

  const goToLink = (link) => {
    navigate(`/${link}`);
  };
  const openNav = () => {
    document.getElementById("mySidebar").style.width = "100%";
    document.getElementById("mySidebar").style.height = "100%";
    document.getElementById("mySidebar").style.background = primaryColor;
    document.getElementById("mySidebar").style.transition = "0.1s";
    document.body.style.overflow = "hidden";
    setIsExpanded(true);
  };
  const closeNav = () => {
    setIsExpanded();
    document.getElementById("mySidebar").style.background = "transparent";
    document.getElementById("mySidebar").style.width = "128px";
    document.getElementById("mySidebar").style.height = "fit-content";
    document.getElementById("mySidebar").style.transition = "0s";
    document.body.style.overflow = "auto";
  };

  return (
    <div
      id="mySidebar"
      className="sidebar cursor-pointer fixed z-50 top-0 left-0 overflow-hidden"
      onClick={isExpanded ? closeNav : openNav}
    >
      <div className="h-full">
        {!isExpanded ? (
          <img
            onClick={openNav}
            src="/assets/button-menu.svg"
            alt="menu"
            title="Open Menu"
            className="mt-[37px] ml-[37px]"
          />
        ) : (
          <div className="h-full relative">
            <div className="flex flex-wrap justify-between items-center">
              <div>
                <img
                  onClick={closeNav}
                  src="/assets/button-menu-close.svg"
                  alt="menu"
                  title="Close Menu"
                  className="ml-[37px] mt-[37px]"
                />
              </div>
              <div style={{ width: windowWidth < 500 && "68%" }}>
                <div
                  style={{
                    fontSize: windowWidth >= mobileSize ? "25px" : "18px",
                  }}
                  className="flex justify-center items-center text-center text-[#343434] tracking-[1.25em] z-10 w-fit text-center cursor-pointer mt-[37px] fadeIn-effect"
                  onClick={closeNav}
                >
                  <span>DYNA</span>
                  <span className="font-black">TEC</span>
                  <span className="font-black tracking-[0]">H</span>
                </div>
              </div>
              <div></div>
            </div>
            <div className="flex h-[calc(100%-85px)] items-center">
              <div
                className="ml-[45px] fadeIn-effect"
                onMouseOver={() =>
                  setLinkedinLogo("/assets/linkedin-white-navbar.svg")
                }
                onMouseLeave={() =>
                  setLinkedinLogo("/assets/linkedin-navbar.svg")
                }
              >
                <a
                  href="https://www.linkedin.com/company/dynatech-2012/about/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={linkedinLogo}
                    alt="LinkedIn"
                    title="Visit our profile in LinkedIn"
                  />
                </a>
              </div>
              <div className="cool-links-div flex flex-col h-full justify-center w-full">
                <div className="cool-link rotate-[-5deg] w-fit">
                  <div className="w-fit">
                    <p
                      id="home-link-navbar"
                      onClick={() => {
                        goToLink("");
                      }}
                      className={`rotate-[5deg] p-4 text-[40px] md:text-8xl hover:text-[#FFF] text-[#343434] font-extrabold`}
                    >
                      Home.
                    </p>
                  </div>
                </div>
                <div className="cool-link rotate-[-5deg] w-fit">
                  <div className="w-fit">
                    <p
                      onClick={() => {
                        goToLink("projects");
                      }}
                      className="rotate-[5deg] p-4 text-[40px] md:text-8xl text-[#343434] hover:text-[#FFF] font-extrabold"
                    >
                      Projects.
                    </p>
                  </div>
                </div>
                <div className="cool-link rotate-[-5deg] w-fit">
                  <div className="w-fit">
                    <p
                      onClick={() => {
                        goToLink("services");
                      }}
                      className="rotate-[5deg] p-4 text-[40px] md:text-8xl text-[#343434] hover:text-[#FFF] font-extrabold"
                    >
                      Services.
                    </p>
                  </div>
                </div>
                <div className="cool-link rotate-[-5deg] w-fit">
                  <div className="w-fit">
                    <p
                      onClick={() => {
                        goToLink("contact");
                      }}
                      className="rotate-[5deg] p-4 text-[40px] md:text-8xl text-[#343434] hover:text-[#FFF] font-extrabold"
                    >
                      Contact us.
                    </p>
                  </div>
                </div>
                <div className="cool-link rotate-[-5deg] w-fit">
                  <div className="w-fit">
                    <p
                      onClick={() => {
                        goToLink("team");
                      }}
                      className="rotate-[5deg] p-4 text-[40px] md:text-8xl text-[#343434] hover:text-[#FFF] font-extrabold"
                    >
                      Team.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                top:
                  (windowWidth > mobileSize
                    ? homeLinkPosition + 70
                    : windowWidth > 300
                    ? homeLinkPosition + 45
                    : homeLinkPosition) + "px",
              }}
              className="fadeIn-effect absolute left-[12%] pl-[82px] points-div w-[86%] bottom-0"
            ></div>
          </div>
        )}
      </div>
    </div>
  );
};
export default BtnMenu;
