import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Contact from "./components/contact";
import Error from "./components/error";
import Project from "./components/project";
import Projects from "./components/projects";
import Team from "./components/team";
import Home from "./components/home";
import { useState } from "react";

function App() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [bgColor] = useState("#0C2141");
  const [darkColor] = useState("#323232");
  const [primaryColor] = useState("#18D1DD");
  const [lightColor] = useState("#FFFFFF");
  const [desktopSize] = useState("1024");
  const [mobileSize] = useState("768");

  window.addEventListener(
    "resize",
    function () {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    },
    true
  );

  return (
    <BrowserRouter>
      <div id="app-div">
        <Routes>
          <Route
            index
            element={
              <Home
                windowWidth={windowWidth}
                darkColor={darkColor}
                lightColor={lightColor}
                desktopSize={desktopSize}
                mobileSize={mobileSize}
                bgColor={bgColor}
                primaryColor={primaryColor}
                windowHeight={windowHeight}
              />
            }
          />
          <Route
            path={`/`}
            element={
              <Home
                windowWidth={windowWidth}
                darkColor={darkColor}
                lightColor={lightColor}
                desktopSize={desktopSize}
                mobileSize={mobileSize}
                bgColor={bgColor}
                primaryColor={primaryColor}
                windowHeight={windowHeight}
              />
            }
          />
          <Route
            path={`/projects`}
            element={
              <Projects
                windowWidth={windowWidth}
                lightColor={lightColor}
                darkColor={darkColor}
                desktopSize={desktopSize}
                mobileSize={mobileSize}
                bgColor={bgColor}
                primaryColor={primaryColor}
                projectsProp={true}
              />
            }
          />
          <Route
            path={`/team`}
            element={
              <Team
                windowWidth={windowWidth}
                darkColor={darkColor}
                lightColor={lightColor}
                desktopSize={desktopSize}
                mobileSize={mobileSize}
                bgColor={bgColor}
                primaryColor={primaryColor}
              />
            }
          />
          <Route
            path={`/contact`}
            element={
              <Contact
                windowWidth={windowWidth}
                darkColor={darkColor}
                lightColor={lightColor}
                desktopSize={desktopSize}
                mobileSize={mobileSize}
                bgColor={bgColor}
                primaryColor={primaryColor}
              />
            }
          />
          <Route
            path={`/services`}
            element={
              <Project
                services={true}
                windowWidth={windowWidth}
                darkColor={darkColor}
                lightColor={lightColor}
                desktopSize={desktopSize}
                mobileSize={mobileSize}
                bgColor={bgColor}
                primaryColor={primaryColor}
              />
            }
          />
          <Route
            path={`/project/:id`}
            element={
              <Project
                windowWidth={windowWidth}
                darkColor={darkColor}
                lightColor={lightColor}
                desktopSize={desktopSize}
                mobileSize={mobileSize}
                bgColor={bgColor}
                primaryColor={primaryColor}
              />
            }
          />
          <Route
            path="*"
            element={
              <Error
                windowWidth={windowWidth}
                darkColor={darkColor}
                lightColor={lightColor}
                desktopSize={desktopSize}
                mobileSize={mobileSize}
                bgColor={bgColor}
                primaryColor={primaryColor}
              />
            }
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
