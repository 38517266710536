import BlockAsset from "../block-asset";

const Design4AssetsOptionAType7 = ({
  assets,
  block,
  windowWidth,
  mobileSize,
}) => {
  return (
    <div className="flex w-full">
      <div
        className="w-1/2 mr-1"
        style={{
          width: windowWidth < mobileSize && "100%",
        }}
      >
        <BlockAsset
          asset={assets?.assetLeftUp}
          title={block.title}
          className={"w-full h-[470px] mb-2"}
        />

        <BlockAsset
          asset={assets?.assetLeftDown}
          title={block.title}
          className={"w-full h-[833px]"}
        />
      </div>
      <div
        className="w-1/2 ml-1"
        style={{
          width: windowWidth < mobileSize && "100%",
        }}
      >
        <BlockAsset
          asset={assets?.assetRightUp}
          title={block.title}
          className={"w-full h-[833px] mb-2"}
        />

        <BlockAsset
          asset={assets?.assetRightDown}
          title={block.title}
          className={"w-full h-[470px] "}
        />
      </div>
    </div>
  );
};
export default Design4AssetsOptionAType7;
