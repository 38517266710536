import { useNavigate } from "react-router-dom";
import "../styles/navbar.css";

const Navbar = ({
  primaryColor,
  mobileSize,
  windowWidth,
  lightColor,
  desktopSize,
  isActive,
}) => {
  let lastScrollTop = 0;
  window.addEventListener(
    "scroll",
    function () {
      // or window.addEventListener("scroll"....
      var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
      if (st > lastScrollTop && document.getElementById("navbar")) {
        // downscroll code
        document.getElementById("navbar").style.animation =
          "opacityOutNavbar 0.5s";
        document.getElementById("navbar").style.opacity = "0";
      } else if (st < lastScrollTop && document.getElementById("navbar")) {
        document.getElementById("navbar").style.animation =
          "opacityInNavbar 0.5s";
        document.getElementById("navbar").style.opacity = "100%";
      }
      lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
    },
    false
  );
  const navigate = useNavigate();
  const goToLink = (link) => {
    navigate(`/${link}`);
  };
  return (
    <div
      id="navbar"
      className="flex justify-between items-center p-5 fixed top-0 z-20 w-full bg-[#0C2141] opacity-1 flex-wrap h-[80px]"
    >
      {windowWidth >= desktopSize && (
        <div
          style={{
            color: primaryColor,
            fontSize: windowWidth > desktopSize ? "24px" : "20px",
          }}
          className="flex justify-start items-center text-center tracking-[1.25em] cursor-pointer w-fit"
          onClick={() => {
            goToLink("");
          }}
        >
          <span>DYNA</span>
          <span className="font-black">TEC</span>
          <span className="font-black tracking-[0]">H</span>
        </div>
      )}
      {windowWidth <= desktopSize && windowWidth > mobileSize && (
        <div
          className="flex ml-4 cursor-pointer"
          onClick={() => {
            goToLink("");
          }}
        >
          <img
            src="/assets/start-logo-blue-1.svg"
            alt="Dynatech"
            className="w-[30px]"
          />
          <img
            src="/assets/start-logo-blue-2.svg"
            alt="Dynatech"
            className="w-[30px]"
          />
        </div>
      )}
      <div
        className="flex justify-around items-center flex-wrap w-[40%]"
        style={{
          color: lightColor,
          width: windowWidth > desktopSize ? "40%" : "45%",
        }}
      >
        <p
          style={{
            fontSize: windowWidth > desktopSize ? "18px" : "14px",
            color: isActive === "Projects" ? primaryColor : lightColor,
          }}
          className="cursor-pointer hover:text-[#18D1DD] mx-2"
          onClick={() => {
            goToLink("projects");
          }}
        >
          Projects
        </p>
        <p
          style={{
            fontSize: windowWidth > desktopSize ? "18px" : "14px",
            color: isActive === "Services" ? primaryColor : lightColor,
          }}
          className="cursor-pointer hover:text-[#18D1DD] mx-2"
          onClick={() => {
            goToLink("services");
          }}
        >
          Services
        </p>
        <p
          style={{
            fontSize: windowWidth > desktopSize ? "18px" : "14px",
            color: isActive === "Contact" ? primaryColor : lightColor,
          }}
          className="cursor-pointer hover:text-[#18D1DD] mx-2"
          onClick={() => {
            goToLink("contact");
          }}
        >
          Contact us
        </p>
        <p
          style={{
            fontSize: windowWidth > desktopSize ? "18px" : "14px",
            color: isActive === "Team" ? primaryColor : lightColor,
          }}
          className="cursor-pointer hover:text-[#18D1DD] mx-2"
          onClick={() => {
            goToLink("team");
          }}
        >
          Team
        </p>
      </div>
    </div>
  );
};
export default Navbar;
