import { useNavigate } from "react-router-dom";
import "../styles/footer.css";
import Portatil2Footer from "./background/portatil-2-footer";

const Footer = ({
  isHome,
  mobileSize,
  windowWidth,
  primaryColor,
  desktopSize,
  lightColor,
  isActive,
}) => {
  const navigate = useNavigate();
  const goToLink = (link) => {
    navigate(`/${link}`);
  };
  return (
    <div className="z-10 bg-[#252525] w-full relative">
      {windowWidth > desktopSize && isHome && <Portatil2Footer />}
      <div
        className="flex items-center flex-col w-full min-h-[380px] footer-bg-div text-[#DFECF0]"
        style={{
          justifyContent: windowWidth >= mobileSize && "center",
          padding: windowWidth < mobileSize && 0,
          paddingTop: windowWidth < mobileSize && "40px",
        }}
      >
        <div
          style={{ color: lightColor }}
          className="flex justify-center items-center text-center text-[21px] tracking-[1.25em] z-10 w-fit text-center cursor-pointer"
          onClick={() => {
            goToLink("");
          }}
        >
          <span>DYNA</span>
          <span className="font-black">TEC</span>
          <span className="font-black tracking-[0]">H</span>
        </div>
        <div
          className="flex flex-wrap w-full justify-around items-center mt-6"
          style={{
            flexDirection: windowWidth < mobileSize ? "column" : "row",
            padding: windowWidth < mobileSize && 0,
          }}
        >
          <div
            className="flex flex-col justify-start items-center h-[180px] z-10"
            style={{
              height: windowWidth < mobileSize && "fit-content",
            }}
          >
            <p className="text-[20px] font-bold">GET IN TOUCH</p>

            <div
              className="flex flex-col justify-start items-center h-[180px] mt-6"
              style={{
                height: windowWidth < mobileSize && "fit-content",
              }}
            >
              <div className="flex justify-center items-center">
                <img src="/assets/email-icon.svg" alt="email" />
                <a href="mailto:info@dynatech2012.com" className="">
                  <span
                    style={{ color: primaryColor }}
                    className="ml-3 cursor-pointer"
                  >
                    info@dynatech2012.com
                  </span>
                </a>
              </div>
              <a
                href="https://www.linkedin.com/company/dynatech-2012/about/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="mt-6 cursor-pointer"
                  src="/assets/footer-linkedin-icon.svg"
                  alt="social media"
                />
              </a>
            </div>
          </div>
          <div
            className="flex flex-col justify-start items-start h-[180px] "
            style={{
              marginTop: windowWidth < mobileSize ? "40px" : "0",
              height: windowWidth < mobileSize && "fit-content",
            }}
          >
            <p
              className="text-[20px] cursor-pointer"
              style={{ color: isActive === "Projects" && primaryColor }}
              onClick={() => {
                goToLink("projects");
              }}
            >
              Projects
            </p>
            <p
              className="text-[20px] mt-2 cursor-pointer"
              style={{ color: isActive === "Services" && primaryColor }}
              onClick={() => {
                goToLink("services");
              }}
            >
              Services
            </p>
            <p
              className="text-[20px] mt-2 cursor-pointer"
              style={{ color: isActive === "Contact" && primaryColor }}
              onClick={() => {
                goToLink("contact");
              }}
            >
              Contact us
            </p>
            <p
              className="text-[20px] mt-2 cursor-pointer"
              style={{ color: isActive === "Team" && primaryColor }}
              onClick={() => {
                goToLink("team");
              }}
            >
              Team
            </p>
          </div>

          <div
            className="flex flex-col justify-center items-center w-full text-center"
            style={{
              height: windowWidth < mobileSize && "fit-content",
              marginTop: windowWidth < mobileSize ? "40px" : "0",
              marginBottom: windowWidth < mobileSize ? "40px" : "0",
            }}
          >
            <p className="text-[14px] px-5">
              © {new Date().getFullYear()} Dyna Tech 2012 | Terms & Conditions |
              Privacy Policy
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
