import BlockAsset from "../block-asset";

const Design3AssetsType7 = ({
  assets,
  block,
}) => {
  return (
    <div className="flex w-full flex-col">
      <div className="flex mb-2">
        <div className="w-1/2 mr-1">
          <BlockAsset
            asset={assets?.assetLeftUp}
            title={block.title}
            className={"w-full h-[470px]"}
          />
        </div>
        <div className="w-1/2 ml-1">
          <BlockAsset
            asset={assets?.assetRightUp}
            title={block.title}
            className={"w-full h-[470px]"}
          />
        </div>
      </div>

      <div className="w-full">
        <BlockAsset
          asset={assets?.assetDown}
          title={block.title}
          className={"w-full h-[800px]"}
        />
      </div>
    </div>
  );
};
export default Design3AssetsType7;
