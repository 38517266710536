import { useState } from "react";
import DesignLeftType6 from "./type-6/design-left-type-6";
import DesignRightType6 from "./type-6/design-right-type-6";

const BlockType6 = ({
  block,
  windowWidth,
  sidePadding,
  darkColor,
  lightColor,
  primaryColor,
  desktopSize,
  mobileSize,
}) => {
  const [blockContent] = useState(block.content);
  return (
    <div className="flex flex-col">
      {blockContent?.map((content, key) => {
        if (!content.inverted) {
          return (
            <div key={key}>
              <DesignLeftType6
                windowWidth={windowWidth}
                block={block}
                content={content}
                sidePadding={sidePadding}
                darkColor={darkColor}
                lightColor={lightColor}
                primaryColor={primaryColor}
                desktopSize={desktopSize}
                mobileSize={mobileSize}
              />
            </div>
          );
        } else {
          return (
            <div key={key}>
              <DesignRightType6
                windowWidth={windowWidth}
                block={block}
                content={content}
                sidePadding={sidePadding}
                darkColor={darkColor}
                lightColor={lightColor}
                primaryColor={primaryColor}
                desktopSize={desktopSize}
                mobileSize={mobileSize}
              />
            </div>
          );
        }
      })}
    </div>
  );
};

export default BlockType6;
