import { useEffect } from "react";
import { scrollToTop } from "../lib/utils";
import BtnMenu from "./btn-menu";
import Footer from "./footer";
import Navbar from "./navbar";

const Contact = ({
  windowWidth,
  darkColor,
  mobileSize,
  lightColor,
  primaryColor,
  desktopSize,
  bgColor,
}) => {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div
      style={{ color: lightColor }}
      className={`flex flex-col justify-between items-center h-screen w-full text-center`}
    >
      {windowWidth > mobileSize && (
        <Navbar
          windowWidth={windowWidth}
          lightColor={lightColor}
          darkColor={darkColor}
          desktopSize={desktopSize}
          mobileSize={mobileSize}
          bgColor={bgColor}
          primaryColor={primaryColor}
          isActive={"Contact"}
        />
      )}
      {windowWidth <= mobileSize && (
        <BtnMenu
          windowWidth={windowWidth}
          mobileSize={mobileSize}
          primaryColor={primaryColor}
        />
      )}
      <div></div>
      <div className="min-h-[500px] flex justify-center items-center flex-col">
        <p className="text-[40px] font-bold mb-10">Contact us</p>
        <a href="mailto:info@dynatech2012.com" className="">
        We're ready to become your ditial partner.<br/>
        Send us an email and we'll get back to you shortly.<br/>
          <span style={{ color: primaryColor }}>info@dynatech2012.com</span>
        </a>
      </div>
      <Footer
        windowWidth={windowWidth}
        primaryColor={primaryColor}
        desktopSize={desktopSize}
        lightColor={lightColor}
        mobileSize={mobileSize}
        isActive={"Contact"}
      />
    </div>
  );
};
export default Contact;
