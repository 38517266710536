import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getExperienceDB } from "../lib/db";
import "../styles/experience.css";
const Experience = ({
  windowWidth,
  bgColor,
  lightColor,
  desktopSize,
  mobileSize,
}) => {
  const [experience, setExperience] = useState();
  const [animation, setAnimation] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    getExperience();
  }, []);


  useEffect(() => {
    // if (windowWidth < 785) {
    if (windowWidth < mobileSize) {
      setAnimation({
        action: "MoveLeftRightMobile",
        delay: 4,
        duration: `${experience?.length * 4}s`,
      });
    } else if (windowWidth < desktopSize) {
      setAnimation({
        action: "MoveLeftRight",
        delay: 4,
        duration: `${experience?.length * 4}s`,
      });
    } else if (windowWidth >= desktopSize) {
      setAnimation({
        action: "MoveUpDown",
        delay: 4,
        duration: `${experience?.length * 4}s`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowWidth, experience]);

  const getExperience = async () => {
    setExperience(await getExperienceDB());
  };

  return (
    <div
      style={{
        background: bgColor,
        marginBottom: windowWidth > desktopSize && "150px",
      }}
      className={`w-full overflow-hidden relative`}
    >
      <div
        className="flex justify-between items-center mx-[100px] bg-[#6787DA] rounded-xl"
        style={{
          flexDirection: windowWidth < desktopSize && "column",
          justifyContent: windowWidth < desktopSize && "start",
          margin: windowWidth < desktopSize && 0,
          borderRadius: windowWidth < desktopSize && 0,
          height: windowWidth >= desktopSize && "671px",
        }}
      >
        <div
          className="flex flex-col pl-[135px] w-1/2"
          style={{
            width: windowWidth < desktopSize && "100%",
            paddingLeft: windowWidth < desktopSize && "0",
            alignItems: windowWidth < desktopSize && "center",
          }}
        >
          <p
            className={`font-bold text-[34px] px-2`}
            style={{
              textAlign: windowWidth < desktopSize && "center",
              marginTop: windowWidth < desktopSize && "40px",
              color: lightColor,
            }}
          >
            Say hello!
          </p>
          <p
            className={`text-[18px] my-[27px] px-2`}
            style={{
              color: lightColor,
              textAlign: windowWidth < desktopSize && "center",
            }}
          >
            We’d love to hear about you and your product or idea. In the
            meantime, here are some fun facts about us and our experience
          </p>
          <button
            onClick={() => navigate("/contact")}
            className="text-[#FEECEF] text-sm font-bold h-[42px] w-[245px] border mx-2 cursor-pointer z-20"
          >
            GET IN TOUCH
          </button>
        </div>
        <div
          className="relative h-full flex flex-col items-end pl-[135px] w-1/2"
          style={{
            width: windowWidth < desktopSize && "100%",
            alignItems: windowWidth < desktopSize && "center",
            justifyContent: windowWidth < desktopSize && "center",
            flexDirection: windowWidth < desktopSize && "row",
            height: windowWidth < desktopSize && "180px",
            padding: windowWidth < desktopSize && "0",
          }}
        >
          {experience?.map((item, key) => {
            return (
              <div
                key={key}
                style={{
                  opactiy: 0,
                  display: "flex",
                  alignItems: "center",
                  borderRadius: "16px",
                  position: "absolute",
                  right: 0,
                  bottom:
                    windowWidth >= desktopSize
                      ? "-200px"
                      : windowWidth < 758
                      ? "-160px"
                      : windowWidth < desktopSize && "-140px",
                  left: windowWidth < desktopSize && "-200px",
                  backgroundColor: item.color,
                  marginRight:
                    key % 2 === 0 && windowWidth >= desktopSize
                      ? "55px"
                      : key % 2 !== 0 && windowWidth >= desktopSize
                      ? "110px"
                      : null,
                  animationName: animation.action,
                  animationDuration: animation.duration,
                  animationTimingFunction: "linear",
                  animationIterationCount: "infinite",
                  animationDelay: `${key * animation.delay}s`,
                  marginTop: windowWidth < desktopSize && "80px",
                  marginBottom: windowWidth < desktopSize && "80px",
                  width:
                    windowWidth < 758
                      ? "160px"
                      : windowWidth < desktopSize
                      ? "174px"
                      : "284px",
                  height:
                    windowWidth < 758
                      ? "200px"
                      : windowWidth < desktopSize
                      ? "190px"
                      : "150px",
                }}
              >
                <div
                  className="p-4"
                >
                  <p className="text-[18px]">{item.title}</p>
                  <p
                    className="text-[18px]"
                    style={{ fontSize: windowWidth < mobileSize && "16px" }}
                  >
                    {item.text}
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {windowWidth < desktopSize && (
        <div className="h-[150px]" style={{ background: bgColor }}></div>
      )}
    </div>
  );
};

export default Experience;
