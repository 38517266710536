import { firebase } from "../lib/firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { get, getDatabase, ref } from "firebase/database";

const db = getFirestore(firebase);
const dbRealTime = getDatabase();

export const getProjectsDB = async (field, filterProjects) => {
  let q;
  if (field === "projects") {
    if (filterProjects && filterProjects.length > 0) {
      q = query(
        collection(db, "projects"),
        where("isActive", "==", true),
        where("technology", "array-contains-any", filterProjects),
        orderBy("order")
      );
    } else {
      q = query(
        collection(db, "projects"),
        where("isActive", "==", true),
        orderBy("order")
      );
    }
  }
  if (field === "home") {
    q = query(
      collection(db, "projects"),
      where("isActive", "==", true),
      where("isHome", "==", true),
      orderBy("homeOrder")
    );
  }
  const querySnapshot = await getDocs(q);
  let data = [];
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });
  return data;
};

export const getProjectDataDB = async (projectId) => {
  const fullProjectsSnap = await getDoc(doc(db, "full_projects", projectId));
  if (fullProjectsSnap.exists()) {
    return fullProjectsSnap.data();
  } else {
    return null;
  }
};

export const getServicesDataDB = async () => {
  const servicesSnap = await getDoc(doc(db, "services", "services"));
  if (servicesSnap.exists()) {
    return servicesSnap.data();
  } else {
    return null;
  }
};

export const getInfoDB = async () => {
  const info = await get(query(ref(dbRealTime, "/info")));
  return info.val();
};

export const getHomeWordsDB = async () => {
  const homeWords = await get(query(ref(dbRealTime, "/homeWords/")));
  return Object.values(homeWords.val());
};

export const getTeamDB = async () => {
  let refTeam = ref(dbRealTime, "/team_EN/");
  const team = await get(query(refTeam));
  return Object.values(team.val());
};

export const getExperienceDB = async () => {
  let refExperience = ref(dbRealTime, "/experience/");
  const experience = await get(query(refExperience));
  return Object.values(experience.val());
};

export const getMobilePicturesDB = async () => {
  let refMobilePictures = ref(dbRealTime, "/mobilePictures/");
  const mobilePictures = await get(query(refMobilePictures));
  return Object.values(mobilePictures.val());
};

export const getCarrouselImagesDB = async () => {
  let refCarrousel = ref(dbRealTime, "/carrousel/");
  const carrouselImages = await get(query(refCarrousel));
  return Object.values(carrouselImages.val());
};
