import { useEffect } from "react";

const CompanyInfo2 = ({
  companyInfoPosition,
  positionToShowDiv,
  windowWidth,
}) => {
  useEffect(() => {
    if (
      companyInfoPosition + positionToShowDiv < window.innerHeight &&
      companyInfoPosition > 0
    ) {
      document.getElementById("company-info-2-div").style.animation =
        "opacityIn 2.5s";
      document.getElementById("company-info-2-div").style.opacity = "1";
    } else if (window.scrollY === 0) {
      document.getElementById("company-info-2-div").style.animation =
        "opacityOut 0.5s";
      document.getElementById("company-info-2-div").style.opacity = "0";
    }
  }, [companyInfoPosition, positionToShowDiv]);
  return (
    <div
      style={{ marginRight: windowWidth >= 1250 && "90px" }}
      className="w-[343px] mb-[90px]"
    >
      <p className="text-[34px] font-bold">It’s not only about the software</p>
      <p className="text-[16px] mt-6">
        More often than not, in software ideas and digital initiatives, there is
        an additional key element: hardware. Either an existing piece of
        hardware that needs integration with the software ecosystem or a
        brand-new hardware development, we are in and we can help.
      </p>
      <p className="text-[16px] mt-6">
        Our experience and background in electronics and hardware development
        enables us to better integrate any hardware like Bluetooth, CANBUS,
        NMEA2K, etc. into the digital ecosystem.
      </p>
    </div>
  );
};
export default CompanyInfo2;
