import { useEffect } from "react";
import { scrollToTop } from "../lib/utils";
import BtnMenu from "./btn-menu";
import Footer from "./footer";
import Navbar from "./navbar";

const Error = ({
  lightColor,
  windowWidth,
  primaryColor,
  desktopSize,
  darkColor,
  mobileSize,
  bgColor,
}) => {
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <div
      style={{ color: lightColor }}
      className={`flex flex-col justify-between items-center h-screen w-full text-center`}
    >
      {windowWidth > mobileSize && (
        <Navbar
          windowWidth={windowWidth}
          lightColor={lightColor}
          darkColor={darkColor}
          desktopSize={desktopSize}
          mobileSize={mobileSize}
          bgColor={bgColor}
          primaryColor={primaryColor}
          isActive={""}
        />
      )}
      {windowWidth <= mobileSize && (
        <BtnMenu
          windowWidth={windowWidth}
          mobileSize={mobileSize}
          primaryColor={primaryColor}
        />
      )}
      <div></div>
      <div className="min-h-[500px] flex justify-center items-center flex-col">
        <p className="text-center px-5">
          Error 404 - the page you are looking for does not exist.
        </p>
      </div>
      <Footer
        windowWidth={windowWidth}
        primaryColor={primaryColor}
        desktopSize={desktopSize}
        lightColor={lightColor}
        mobileSize={mobileSize}
      />
    </div>
  );
};
export default Error;
