const CategoriesType6 = ({ categories, primaryColor }) => {
  return (
    <div className="flex">
      {categories?.map((category, key) => (
        <p
          key={key}
          style={{ color: primaryColor }}
          className={`mr-3 py-1 text-[14px] font-extrabold`}
        >
          {category.toUpperCase()}
        </p>
      ))}
    </div>
  );
};
export default CategoriesType6;
