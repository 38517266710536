/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { getTeamDB } from "../lib/db";
import { scrollToTop } from "../lib/utils";
import BtnMenu from "./btn-menu";
import Footer from "./footer";
import LoadingSpinner from "./loading-spinner";
import Navbar from "./navbar";

const Team = ({
  windowWidth,
  bgColor,
  darkColor,
  lightColor,
  primaryColor,
  desktopSize,
  mobileSize,
}) => {
  const [isLoading, setIsLoading] = useState();
  const [team, setTeam] = useState();

  useEffect(() => {
    getTeam();
    scrollToTop();
  }, []);

  const getTeam = async () => {
    setIsLoading(true);
    setTeam(await getTeamDB());
    setIsLoading();
  };

  return (
    <div className="flex flex-col justify-between items-center pt-[120px] min-h-[100vh]">
      {windowWidth > mobileSize && (
        <Navbar
          windowWidth={windowWidth}
          lightColor={lightColor}
          darkColor={darkColor}
          desktopSize={desktopSize}
          mobileSize={mobileSize}
          bgColor={bgColor}
          primaryColor={primaryColor}
          isActive={"Team"}
        />
      )}
      {windowWidth <= mobileSize && (
        <BtnMenu
          windowWidth={windowWidth}
          mobileSize={mobileSize}
          primaryColor={primaryColor}
        />
      )}
      {isLoading && <LoadingSpinner />}
      <div className="flex items-center justify-around w-full h-full flex-wrap">
        {!isLoading &&
          team &&
          team.map((teamMember, key) => {
            return (
              <div
                key={key}
                style={{ color: lightColor }}
                className="flex flex-col items-center justify-center mx-4"
              >
                <p className="text-xl my-2 text-center">
                  {teamMember.memberName} {teamMember.memberMiddleName}
                </p>
                {teamMember.profilePic && (
                  <img
                    className="mb-2 w-[350px] h-[420px] rounded-lg"
                    src={teamMember.profilePic}
                    alt="member"
                  />
                )}
                <p className="mb-2 text-center">
                  {teamMember.memberDescription_EN}
                </p>
                <a target="_blank" rel="noreferrer" href={teamMember.linkedIn}>
                  <img
                    className="w-8 mb-4"
                    src="/assets/linkedin.png"
                    alt="member"
                  />
                </a>
              </div>
            );
          })}
      </div>
      {team && (
        <Footer
          windowWidth={windowWidth}
          primaryColor={primaryColor}
          desktopSize={desktopSize}
          lightColor={lightColor}
          mobileSize={mobileSize}
          isActive={"Team"}
        />
      )}
    </div>
  );
};
export default Team;
