/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getProjectDataDB, getServicesDataDB } from "../lib/db";
import { scrollToTop } from "../lib/utils";
import BtnMenu from "./btn-menu";
import Error from "./error";
import Footer from "./footer";
import LoadingSpinner from "./loading-spinner";
import Navbar from "./navbar";
import BlockType1 from "./project-blocks/block-type-1";
import BlockType2 from "./project-blocks/block-type-2";
import BlockType3 from "./project-blocks/block-type-3";
import BlockType4 from "./project-blocks/block-type-4";
import BlockType5 from "./project-blocks/block-type-5";
import BlockType6 from "./project-blocks/block-type-6";
import BlockType7 from "./project-blocks/block-type-7";
import BlockType8 from "./project-blocks/block-type-8";
import BlockType9 from "./project-blocks/block-type-9";

const Project = ({
  windowWidth,
  bgColor,
  darkColor,
  lightColor,
  primaryColor,
  desktopSize,
  mobileSize,
  services,
}) => {
  const [isLoading, setIsLoading] = useState();
  const [projectId, setProjectId] = useState(useParams("id").id);
  const [projectData, setProjectData] = useState({});
  const [projectContent, setProjectContent] = useState();
  const [sidePadding, setSidePadding] = useState();

  useEffect(() => {
    scrollToTop();
  }, []);

  useEffect(() => {
    if (services) {
      setProjectId("services");
      setProjectData({});
      setProjectContent();
    }
  }, [services]);

  useEffect(() => {
    getProjectData();
  }, [projectId]);

  const getProjectData = async () => {
    setIsLoading(true);
    if (services) {
      setProjectData(await getServicesDataDB());
    } else {
      setProjectData(await getProjectDataDB(projectId));
    }
    setIsLoading();
  };

  useEffect(() => {
    if (projectData) {
      setProjectContent(projectData?.content);
      setSidePadding(projectData?.padding);
    }
  }, [projectData]);

  return (
    <div className="w-full h-screen text-[#FFFFFF]">
      {windowWidth > mobileSize && (
        <Navbar
          windowWidth={windowWidth}
          lightColor={lightColor}
          darkColor={darkColor}
          desktopSize={desktopSize}
          mobileSize={mobileSize}
          bgColor={bgColor}
          primaryColor={primaryColor}
          isActive={services ? "Services" : "Projects"}
        />
      )}
      {windowWidth <= mobileSize && (
        <BtnMenu
          windowWidth={windowWidth}
          mobileSize={mobileSize}
          primaryColor={primaryColor}
        />
      )}
      <div style={{ marginTop: windowWidth > mobileSize && "80px" }}>
        {isLoading && <LoadingSpinner />}
        {/* {projectData && (
        <div className="relative w-full h-screen flex flex-col items-center justify-center">
          <p
            className="absolute text-7xl text-center z-10"
            style={{
              fontSize: windowWidth < 1024 && "40px",
            }}
          >
            {projectData?.full_name}
          </p>
          {projectData.mainImage && (
            <img
              src={projectData?.mainImage}
              alt={projectData?.full_name}
              title={projectData?.full_name}
              className="w-full h-screen  opacity-50"
            />
          )}
        </div>
      )} */}
        {!isLoading &&
          projectData &&
          projectContent?.map((block, key) => {
            return (
              <div key={key} className="w-full">
                {block.type === "1" && (
                  <BlockType1
                    key={key}
                    block={block}
                    windowWidth={windowWidth}
                    sidePadding={sidePadding}
                    desktopSize={desktopSize}
                    mobileSize={mobileSize}
                  />
                )}
                {block.type === "2" && (
                  <BlockType2
                    key={key}
                    block={block}
                    windowWidth={windowWidth}
                    sidePadding={sidePadding}
                    darkColor={darkColor}
                    lightColor={lightColor}
                    primaryColor={primaryColor}
                    desktopSize={desktopSize}
                  />
                )}
                {block.type === "3" && (
                  <BlockType3
                    key={key}
                    block={block}
                    windowWidth={windowWidth}
                    mobileSize={mobileSize}
                  />
                )}
                {block.type === "4" && (
                  <BlockType4
                    key={key}
                    block={block}
                    windowWidth={windowWidth}
                    sidePadding={sidePadding}
                    darkColor={darkColor}
                    lightColor={lightColor}
                    desktopSize={desktopSize}
                  />
                )}
                {block.type === "5" && (
                  <BlockType5
                    key={key}
                    block={block}
                    windowWidth={windowWidth}
                    sidePadding={sidePadding}
                    darkColor={darkColor}
                    lightColor={lightColor}
                    desktopSize={desktopSize}
                    mobileSize={mobileSize}
                  />
                )}
                {block.type === "6" && (
                  <BlockType6
                    key={key}
                    block={block}
                    windowWidth={windowWidth}
                    sidePadding={sidePadding}
                    darkColor={darkColor}
                    lightColor={lightColor}
                    primaryColor={primaryColor}
                    desktopSize={desktopSize}
                    mobileSize={mobileSize}
                    bgColor={bgColor}
                  />
                )}
                {block.type === "7" && (
                  <BlockType7
                    key={key}
                    block={block}
                    windowWidth={windowWidth}
                    sidePadding={sidePadding}
                    darkColor={darkColor}
                    lightColor={lightColor}
                    desktopSize={desktopSize}
                    mobileSize={mobileSize}
                  />
                )}
                {block.type === "8" && (
                  <BlockType8
                    block={block}
                    windowWidth={windowWidth}
                    sidePadding={sidePadding}
                    darkColor={darkColor}
                    lightColor={lightColor}
                    desktopSize={desktopSize}
                    mobileSize={mobileSize}
                  />
                )}
                {block.type === "9" && (
                  <BlockType9
                    block={block}
                    windowWidth={windowWidth}
                    sidePadding={sidePadding}
                    darkColor={darkColor}
                    lightColor={lightColor}
                    desktopSize={desktopSize}
                  />
                )}
              </div>
            );
          })}
        {!projectData && !isLoading && <Error />}
        {projectData && projectContent?.length === 0 && !isLoading && (
          <div className="h-screen flex justify-center items-center flex-col">
            <p className="text-center text-[40px] font-bold px-5">
              {projectData?.full_name}
            </p>
          </div>
        )}
        {projectData && (
          <Footer
            windowWidth={windowWidth}
            primaryColor={primaryColor}
            desktopSize={desktopSize}
            lightColor={lightColor}
            mobileSize={mobileSize}
            isActive={services ? "Services" : "Projects"}
          />
        )}
      </div>
    </div>
  );
};
export default Project;
