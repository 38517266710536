import { useEffect } from "react";

const CompanyInfo4 = ({
  companyInfoPosition,
  positionToShowDiv,
  windowWidth,
}) => {
  useEffect(() => {
    if (
      companyInfoPosition + positionToShowDiv < window.innerHeight &&
      companyInfoPosition > 0
    ) {
      document.getElementById("company-info-4-div").style.animation =
        "opacityIn 2.5s";
      document.getElementById("company-info-4-div").style.opacity = "1";
    } else if (window.scrollY === 0) {
      document.getElementById("company-info-4-div").style.animation =
        "opacityOut 0.5s";
      document.getElementById("company-info-4-div").style.opacity = "0";
    }
  }, [companyInfoPosition, positionToShowDiv]);
  return (
    <div
      style={{ marginLeft: windowWidth >= 1250 && "90px" }}
      className="w-[343px]"
    >
      <p className="text-[34px] font-bold">We work WITH you</p>
      <p className="text-[16px] mt-6">
        When starting a new tech-based project, it can be daunting to think
        about the tech-related human resources you will need and how you will
        manage them.
      </p>
      <p className="text-[16px] mt-6">
        We think there’s a better approach, we build the team for you. We will
        dedicate a fully tailor-made self-managed team of professionals, from
        more senior or business-oriented profiles to more junior or
        tech-specific roles, that will meet the needs of your project.
      </p>
    </div>
  );
};
export default CompanyInfo4;
