import { useEffect, useState } from "react";

const ProjectInfoDiv = ({
  bigDiv,
  projectsProp,
  project,
  keyprop,
  showProjectDescription,
  lightColor,
  darkColor,
  windowWidth,
  isHome,
}) => {
  const [imageSrc, setImageSrc] = useState();
  useEffect(() => {
    if (bigDiv && windowWidth > 1200 && !isHome) {
      setImageSrc(project.cover_image);
    }
    if (!bigDiv && windowWidth > 1200 && !isHome) {
      setImageSrc(
        project.cover_image_small
          ? project.cover_image_small
          : project.cover_image
      );
    }
    if (!isHome && windowWidth <= 1200) {
      setImageSrc(
        project.cover_image_mbl ? project.cover_image_mbl : project.cover_image
      );
    }
    if (isHome && keyprop % 3 === 0 && windowWidth > 1200) {
      setImageSrc(project.cover_image);
    }
    if (isHome && keyprop % 3 !== 0 && windowWidth > 1200) {
      setImageSrc(
        project.cover_image_small
          ? project.cover_image_small
          : project.cover_image
      );
    }
    if (isHome && windowWidth <= 1200) {
      setImageSrc(
        project.cover_image_mbl ? project.cover_image_mbl : project.cover_image
      );
    }
  }, [project, keyprop, bigDiv, isHome, windowWidth]);

  return (
    <div className="h-full w-full" key={keyprop}>
      <div
        className="h-full w-full overflow-hidden"
        style={{ borderRadius: !projectsProp && "5px" }}
      >
        <img
          id={`project-item-home-img-${keyprop}`}
          src={imageSrc}
          alt={project.projectName}
          title={project.projectName}
          className={`h-full w-full`}
        />
      </div>
      <div
        style={{ color: project.dark ? darkColor : lightColor }}
        className={`absolute left-9 bottom-[25px]`}
      >
        <p className="text-[34px] font-semibold">{project.projectName}</p>
        <div className="flex my-2">
          {showProjectDescription !== keyprop + 1 &&
            project.technology.map((technology, key) => {
              return (
                <span key={key} className="mr-1 font-extrabold text-sm">
                  {technology}.
                </span>
              );
            })}
          {showProjectDescription === keyprop + 1 && (
            <span className="mr-1 font-extrabold text-sm">
              {project.project_short_description_en}
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
export default ProjectInfoDiv;
