import { useEffect, useState } from "react";

const ScrollDownStart = ({ desktopSize, primaryColor, windowWidth }) => {
  const [positionToHideDiv] = useState(windowWidth < desktopSize ? 250 : 380);
  const [scrollDownPosition, setScrollDownPosition] = useState(
    document?.getElementById("scroll-down-start-div")?.getBoundingClientRect()
      ?.y
  );

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScrollDownPosition(
        document
          ?.getElementById("scroll-down-start-div")
          ?.getBoundingClientRect()?.top
      );
    });
  }, []);

  useEffect(() => {
    if (scrollDownPosition < positionToHideDiv) {
      document.getElementById("scroll-down-start-div").style.animation =
        "opacityOut 1s";
      document.getElementById("scroll-down-start-div").style.opacity = 0;
    } else {
      document.getElementById("scroll-down-start-div").style.animation =
        "opacityIn 2.5s";
      document.getElementById("scroll-down-start-div").style.opacity = 1;
    }
  }, [positionToHideDiv, scrollDownPosition]);

  return (
    <div
      id="scroll-down-start-div"
      className="flex flex-col justify-center items-center"
      style={{ paddingBottom: windowWidth < desktopSize && "40px" }}
    >
      <div className="flex flex-col justify-center">
        <img
          className=""
          src="/assets/button-scroll-down.svg"
          alt="home"
          title="Dynatech"
        />
        <img
          className="pb-2"
          src="/assets/button-scroll-down.svg"
          alt="home"
          title="Dynatech"
        />
      </div>
      <p style={{ color: primaryColor }} className={`pb-10 text-xs`}>
        scroll down
      </p>
    </div>
  );
};

export default ScrollDownStart;
