import { useEffect, useState } from "react";
import { getHomeWordsDB } from "../lib/db";
import TypeWriterEffect from "react-typewriter-effect";
import CompanyInfo from "./company-info";
import Circle1Start from "./background/circle1-start";
import Circle2Start from "./background/circle2-start";
import Circle4Start from "./background/circle4-start";
import Circle3Start from "./background/circle3-start";
import { scrollToTop } from "../lib/utils";
import Circle6Start from "./background/circle6-start";
import CloudRightStart from "./background/cloud-right-start";
import CloudLeftStart from "./background/cloud-left-start";
import MobileStart from "./background/mobile-start";
import "../styles/background.css";
import ScrollDownStart from "./scroll-down-start";

const Start = ({
  windowWidth,
  bgColor,
  darkColor,
  lightColor,
  primaryColor,
  desktopSize,
  mobileSize,
  windowHeight,
}) => {
  const [homeWords, setHomeWords] = useState();

  useEffect(() => {
    scrollToTop();
    getHomeWords();
  }, []);

  const getHomeWords = async () => {
    setHomeWords(await getHomeWordsDB());
  };

  return (
    <div
      style={{ background: bgColor }}
      className="w-full flex flex-col justify-center items-center relative overflow-x-hidden"
    >
      {<CloudLeftStart />}
      {windowWidth > mobileSize && <Circle1Start />}
      {windowWidth > desktopSize && <Circle2Start />}
      <Circle3Start />
      {windowWidth > desktopSize && <Circle4Start />}
      {windowWidth > desktopSize && <Circle6Start />}
      {<CloudRightStart />}
      <div className="h-screen w-full relative">
      {windowWidth >= mobileSize && (
        <MobileStart windowWidth={windowWidth} windowHeight={windowHeight} />
      )}
        <div className="w-full flex flex-col items-center justify-between h-full">
          <div className="w-full flex flex-col items-center justify-center h-full pt-[120px]">
            {/* <img
              className="h-fit"
              src="/assets/start-logo.svg"
              alt="home"
              title="Dynatech"
            ></img> */}
            <div className="flex mb-4">
              <img src="/assets/start-logo-blue-1.svg" alt="Dynatech" className="w-[50px]"/>
              <img src="/assets/start-logo-blue-2.svg" alt="Dynatech" className="w-[50px]"/>
            </div>
            <div
              style={{ color: primaryColor, fontSize: windowWidth > mobileSize ? "30px" : "22px" }}
              className={`flex justify-center items-center text-center tracking-[1.25em] w-full text-center z-10`}
            >
              <span>DYNA</span>
              <span className="font-black">TEC</span>
              <span className="font-black tracking-[0]">H</span>
            </div>
            <div className="h-[120px] z-10">
              <TypeWriterEffect
                textStyle={{
                  fontFamily: "Montserrat, sans-serif",
                  color: "white",
                  textAlign: "center",
                  paddingRight: windowWidth < mobileSize && "40px",
                  paddingLeft: windowWidth < mobileSize && "40px",
                  fontWeight: 700,
                  fontSize:
                    windowWidth < 500
                      ? "40px"
                      : windowWidth < 800
                      ? "50px"
                      : "80px",
                }}
                startDelay={1000}
                cursorColor="white"
                multiText={homeWords ? homeWords : [""]}
                multiTextDelay={2000}
                typeSpeed={120}
                multiTextLoop={true}
              />
            </div>
          </div>
          <ScrollDownStart
            windowWidth={windowWidth}
            desktopSize={desktopSize}
            primaryColor={primaryColor}
          />
        </div>
      </div>
      <CompanyInfo
        windowWidth={windowWidth}
        darkColor={darkColor}
        lightColor={lightColor}
        desktopSize={desktopSize}
        mobileSize={mobileSize}
        bgColor={bgColor}
      />
    </div>
  );
};

export default Start;
