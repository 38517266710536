import { useEffect, useRef, useState } from "react";
import BlockAsset from "./block-asset";

const BlockType8 = ({
  block,
  windowWidth,
  sidePadding,
  darkColor,
  lightColor,
  desktopSize,
  mobileSize,
}) => {
  const [isDark] = useState(block.dark);
  const [elementsSize, setElementsSize] = useState(block.elementsSize);
  const [titleSize, setTitleSize] = useState(0);
  const [titleHeight, setTitleHeight] = useState(0);

  const ref = useRef(null);

  useEffect(() => {
    if (block) {
      let titleSizeT = 0;
      // eslint-disable-next-line array-callback-return
      block.content.map((item) => {
        if (item.title.length > titleSizeT) titleSizeT = item.title.length;
      });
      setTitleSize(titleSizeT);
    }
  }, [block]);

  useEffect(() => {
    if (titleSize > 0) {
      if (windowWidth < desktopSize) {
        setTitleHeight(Math.ceil((titleSize / 20) * 60));
      } else {
        setTitleHeight(Math.ceil((titleSize / 20) * 33));
      }
    }
  }, [desktopSize, titleSize, windowWidth]);

  return (
    <div
      className="w-full flex flex-wrap  justify-center"
      style={{
        background: isDark ? darkColor : lightColor,
        color: isDark ? lightColor : darkColor,
        paddingLeft: windowWidth > mobileSize && sidePadding?.desktop + "px",
        paddingRight: windowWidth > mobileSize && sidePadding?.desktop + "px",
        paddingTop:
          windowWidth < desktopSize
            ? block?.padding?.mobile?.top + "px"
            : block?.padding?.desktop?.top + "px",
        paddingBottom:
          windowWidth < desktopSize
            ? block?.padding?.mobile?.bottom + "px"
            : block?.padding?.desktop?.bottom + "px",
      }}
    >
      {block?.content.map((item, key) => {
        if (!elementsSize) {
          setElementsSize(238);
        }
        return (
          <div
            key={key}
            className="flex flex-col"
            style={{
              margin: windowWidth > mobileSize && block?.elementsMargin + "px",
            }}
          >
            {item.asset.src && (
              <div
                className={`flex flex-col `}
                style={{
                  width:
                    elementsSize && windowWidth >= desktopSize
                      ? elementsSize + "px"
                      : windowWidth < mobileSize
                      ? "100%"
                      : "238px",
                  // height:
                  //   elementsSize && windowWidth >= desktopSize
                  //     ? elementsSize + "px"
                  //     : "238px",
                }}
              >
                <BlockAsset
                  asset={item.asset}
                  title={item.title}
                  className={`h-full w-full`}
                />
              </div>
            )}
            <div
              className="flex flex-col"
              style={{
                paddingLeft: windowWidth < mobileSize && "20px",
                paddingRight: windowWidth < mobileSize && "20px",
                width:
                  elementsSize && windowWidth >= desktopSize
                    ? elementsSize + "px"
                    : windowWidth < mobileSize
                    ? "100%"
                    : "238px",
              }}
            >
              <div
                style={{ height: titleHeight + "px" }}
                className="mt-4 text-center"
              >
                <p ref={ref} className="font-bold text-[22px]">
                  {item.title}
                </p>
              </div>

              <div
                className="pr-1 min-h-[90px]"
                style={{
                  marginTop: windowWidth > desktopSize ? "16px" : "4px",
                  marginBottom: windowWidth < desktopSize && "20px",
                }}
              >
                <p
                  className="text-[14px] text-justify"
                  dangerouslySetInnerHTML={{ __html: item?.text }}
                ></p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BlockType8;
