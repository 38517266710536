import { useEffect } from "react";

const CompanyInfo3 = ({
  companyInfoPosition,
  positionToShowDiv,
  windowWidth,
}) => {
  useEffect(() => {
    if (
      companyInfoPosition + positionToShowDiv < window.innerHeight &&
      companyInfoPosition > 0
    ) {
      document.getElementById("company-info-3-div").style.animation =
        "opacityIn 2.5s";
      document.getElementById("company-info-3-div").style.opacity = "1";
    } else if (window.scrollY === 0) {
      document.getElementById("company-info-3-div").style.animation =
        "opacityOut 0.5s";
      document.getElementById("company-info-3-div").style.opacity = "0";
    }
  }, [companyInfoPosition, positionToShowDiv]);
  return (
    <div
      style={{ marginLeft: windowWidth >= 1250 && "90px" }}
      className="w-[343px] mb-[90px]"
    >
      <p className="text-[34px] font-bold">We’ve been there</p>
      <p className="text-[16px] mt-6">
        When thinking about launching or improving a software-based initiative,
        you want someone that knows the tips, tricks, and pains of launching and
        maintaining a software system.
      </p>
      <p className="text-[16px] mt-6">
        We can help, we are a technical team that can speak “business” and who
        you easily can relate with “no-developer-jargon” through the process. We
        understand your goals and motivations and have been and have worked with
        multiple companies that have gone, with us, through the same process.
      </p>
    </div>
  );
};
export default CompanyInfo3;
