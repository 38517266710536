import { useEffect, useState } from "react";
import { getStorage, ref, getDownloadURL } from "firebase/storage";

const BlockAsset = ({ asset, title, className }) => {
  const [assetSrc, setAssetSrc] = useState();
  const storage = getStorage();

  useEffect(() => {
    if (asset?.type === "image" || asset?.type === "video") {
      getImageSrc();
    } else if (asset?.type === "imageWeb" || asset?.type === "videoWeb") {
      setAssetSrc(asset.src);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asset]);

  const getImageSrc = async () => {
    await getDownloadURL(ref(storage, asset.src))
      .then((url) => {
        setAssetSrc(url);
      })
      .catch((error) => {
        console.log("Error to get image", error);
      });
  };

  if (asset?.type === "image" || asset?.type === "imageWeb") {
    return (
      <img src={assetSrc} title={title} alt={title} className={className}/>
    );
  }
  if (asset?.type === "video") {
    return (
      <video
        src={assetSrc}
        loop
        autoPlay
        muted
        poster={""}
        className={className}
      />
    );
  }else if( asset?.type === "videoWeb") {
    return (
      <video
        src={assetSrc}
        muted
        poster={""}
        className={className}
      />
    );
  }
};

export default BlockAsset;
