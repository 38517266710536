import BlockAsset from "./block-asset";

const BlockType1 = ({
  block,
  windowWidth,
  sidePadding,
  desktopSize,
  mobileSize,
}) => {
  return (
    <div
      style={{ marginTop: windowWidth > mobileSize && "80px" }}
      className="relative w-full h-screen flex flex-col items-center justify-center"
    >
      {/* <div
        className="absolute top-[37px] z-10"
        style={{ top: windowWidth < mobileSize && "120px" }}
      >
        <img src="/assets/logo-projects.svg" alt="logo" />
      </div> */}
      <div
        className="absolute z-10"
        style={{
          left:
            windowWidth < desktopSize
              ? sidePadding.mobile + "px"
              : sidePadding.desktop + "px",
        }}
      >
        <p
          className=" font-extrabold text-left"
          style={{
            fontSize: windowWidth > mobileSize ? "72px" : "42px",
            width: windowWidth < desktopSize && "100%",
            left: windowWidth < desktopSize && "0",
          }}
        >
          {block?.title}
        </p>
        <div
          className="flex flex-wrap"
          style={{ marginTop: windowWidth < desktopSize && "24px" }}
        >
          {block?.categories?.map((category, key) => (
            <p key={key} className="font-bold text-[32px] mr-2">
              {category.toUpperCase()}.
            </p>
          ))}
        </div>
      </div>
      <BlockAsset
        asset={
          windowWidth > mobileSize
            ? block?.asset
            : block?.assetMobile
            ? block?.assetMobile
            : block?.asset
        }
        // title={block.title}
        className={"w-full h-full object-cover opacity-60"}
      />
    </div>
  );
};

export default BlockType1;
