import { useState } from "react";

const BlockType9 = ({
  block,
  windowWidth,
  sidePadding,
  darkColor,
  lightColor,
  desktopSize,
}) => {
  const [isDark] = useState(block.dark);

  return (
    <div
      className="w-full flex flex-wrap items-center justify-center"
      style={{
        background: isDark ? darkColor : lightColor,
        color: isDark ? lightColor : darkColor,
        paddingLeft:
          windowWidth < desktopSize
            ? sidePadding?.mobile + "px"
            : sidePadding?.desktop + "px",
        paddingRight:
          windowWidth < desktopSize
            ? sidePadding?.mobile + "px"
            : sidePadding?.desktop + "px",
        paddingTop:
          windowWidth < desktopSize
            ? block?.padding?.mobile?.top + "px"
            : block?.padding?.desktop?.top + "px",
        paddingBottom:
          windowWidth < desktopSize
            ? block?.padding?.mobile?.bottom + "px"
            : block?.padding?.desktop?.bottom + "px",
      }}
    >
      {block?.content.map((item, key) => {
        return (
          <div
            key={key}
            className="flex flex-col w-[238px]"
            style={{ margin: block?.elementsMargin + "px" }}
          >
            <div className="max-h-[40px] overflow-y-auto">
              <p className="font-bold text-[22px]">{item.title}</p>
            </div>

            <div
              className="mt-2 pr-1 overflow-y-auto"
              style={{
                marginBottom: windowWidth < desktopSize && "20px",
              }}
            >
              <p
                className="text-[14px] mt-2"
                dangerouslySetInnerHTML={{ __html: item?.text }}
              ></p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BlockType9;
