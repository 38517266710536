import { useEffect, useState } from "react";
import CompanyInfo1 from "./company-info/company-info-1";
import CompanyInfo2 from "./company-info/company-info-2";
import CompanyInfo3 from "./company-info/company-info-3";
import CompanyInfo4 from "./company-info/company-info-4";
import "../styles/company-info.css";
const CompanyInfo = ({ windowWidth, lightColor, desktopSize, mobileSize }) => {
  const [companyInfo1Position, setCompanyInfo1Position] = useState(
    document?.getElementById("company-info-1-div")?.getBoundingClientRect()?.y
  );
  const [companyInfo2Position, setCompanyInfo2Position] = useState(
    document?.getElementById("company-info-2-div")?.getBoundingClientRect()?.y
  );
  const [companyInfo3Position, setCompanyInfo3Position] = useState(
    document?.getElementById("company-info-3-div")?.getBoundingClientRect()?.y
  );
  const [companyInfo4Position, setCompanyInfo4Position] = useState(
    document?.getElementById("company-info-4-div")?.getBoundingClientRect()?.y
  );

  const [positionToShowDiv] = useState(windowWidth < desktopSize ? 180 : 250);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      // setScrollPosition(document.documentElement.scrollTop);
      setCompanyInfo1Position(
        document?.getElementById("company-info-1-div")?.getBoundingClientRect()
          ?.top
      );
      setCompanyInfo2Position(
        document?.getElementById("company-info-2-div")?.getBoundingClientRect()
          ?.top
      );
      setCompanyInfo3Position(
        document?.getElementById("company-info-3-div")?.getBoundingClientRect()
          ?.top
      );
      setCompanyInfo4Position(
        document?.getElementById("company-info-4-div")?.getBoundingClientRect()
          ?.top
      );
    });
  }, []);

  return (
    <div
      className={`flex justify-center w-[70%] pt-[70px] flex-wrap relative`}
      style={{
        flexDirection: windowWidth < 1250 && "column",
        justifyContent: windowWidth < 1250 && "center",
        alignItems: windowWidth < 1250 && "center",
        width: windowWidth < 1250 && "90%",
        color: lightColor,
      }}
    >
      <div>
        <div id="company-info-1-div" className="opacity-0">
          <CompanyInfo1
            companyInfoPosition={companyInfo1Position}
            positionToShowDiv={positionToShowDiv}
            windowWidth={windowWidth}
          />
        </div>
        <div id="company-info-2-div" className="opacity-0">
          <CompanyInfo2
            companyInfoPosition={companyInfo2Position}
            positionToShowDiv={positionToShowDiv}
            windowWidth={windowWidth}
          />
        </div>
      </div>
      <div style={{ display: windowWidth < 1250 && "none" }}>
        <img src="/assets/line-company-info.svg" alt="line" />
      </div>
      <div
        className="mt-[220px]"
        style={{
          marginTop: windowWidth < 1250 && "0",
        }}
      >
        <div id="company-info-3-div" className="opacity-0">
          <CompanyInfo3
            companyInfoPosition={companyInfo3Position}
            positionToShowDiv={positionToShowDiv}
            windowWidth={windowWidth}
          />
        </div>
        <div id="company-info-4-div" className="opacity-0">
          <CompanyInfo4
            companyInfoPosition={companyInfo4Position}
            positionToShowDiv={positionToShowDiv}
            windowWidth={windowWidth}
          />
        </div>
      </div>
    </div>
  );
};
export default CompanyInfo;
